import React, { useState, useEffect } from 'react';
import { useStore, useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import { Container, Button, Label, Input, Row, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, InputGroup, InputGroupAddon, InputGroupText, FormText } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { search } from 'react-icons-kit/feather';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Loader from 'react-loader-spinner'

import { LoadingScreen } from '../loading-screen';
import Toggle from 'react-toggle';

import moment from 'moment';
const FileDownload = require('js-file-download');

import '../../styles/users.css'

moment.locale('en-nz')
export const Users = () => {
  const { auth } = useSelector(state => state.firebaseData);
  const [updateModal, setUpdateModal] = useState(false);
  const [filteredData, setFilteredData] = useState([])
  const [openUser, setOpenUser] = useState(null);
  const [oldEmail, setOldEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [openPasswordReset, setOpenPasswordReset] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [renewLoading, setRenewLoading] = useState(false);
  const [applicatorListLoading, setApplicatorListLoading] = useState(false);

  useFirestoreConnect([
    { collection: 'users', storeAs: 'users' },
  ])


  const { status: { requesting }, data: { users } } = useStore().getState().firestoreData;

  useEffect(() => {
    filter();
  }, [searchValue, users])


  const columns = [
    {
      name: 'Applicator',
      selector: 'applicatorName',
      sortable: true,
    },
    {
      name: 'email',
      selector: 'email',
      sortable: true,
      right: true,
    },
    {
      name: 'Applicator #',
      selector: 'applicatorNumber',
      sortable: false,
      right: true,
    },
    {
      name: 'Expiry',
      selector: 'applicatorExpiry',
      sortable: true,
      right: true,
      format: row => moment.unix(row.applicatorExpiry).format('DD/MM/YYYY')
    },
    {
      name: 'Cert Download',
      selector: 'certUrl',
      sortable: true,
      right: true,
      format: row => row.hasOwnProperty('certUrl') ? <a target="_blank" href={row.certUrl}>View</a> : null
    },
  ];

  const conditionalRowStyles = [
    {
      when: row => row.accessDisabled,
      style: {
        backgroundColor: 'rgba(0,0,0,0.66)',
        color: 'white',
      },
      when: row => moment.unix(row.applicatorExpiry) < moment.now(),
      style: {
        backgroundColor: 'rgba(178, 35, 35, 0.8)',
        color: 'white',
      },
    },
  ];

  const toggle = () => setUpdateModal(!updateModal);

  const filter = () => {
    const inputValue = searchValue.trim().toLowerCase();
    const inputLength = inputValue.length;

    setFilteredData(inputLength === 0 ? _.map(users) : _.filter(users, (user) => {
      return user.applicatorName.toLowerCase().slice(0, inputLength) === inputValue || user.email.toLowerCase().toLowerCase().slice(0, inputLength) === inputValue;
    }))
  };

  const userUpdate = () => {
    if (_.isEmpty(openUser)) {
      return null;
    }
    return (
      <Modal isOpen={updateModal}>
        <ModalHeader>{openUser.applicatorName}</ModalHeader>
        <ModalBody>
          <Row form>
            <Col>
              <Label>Applicator Name:</Label>
              <Input type='text' value={openUser.applicatorName} onChange={(e) => setOpenUser({ ...openUser, applicatorName: e.target.value })} />
            </Col>
            <Col>
              <Label>Applicator Email:</Label>
              <Input type='email' value={openUser.email} onChange={(e) => setOpenUser({ ...openUser, email: e.target.value })} />
            </Col>
          </Row>
          <Row form>
            <Col>
              <Label>Applicator #:</Label>
              <Input type='text' value={openUser.applicatorNumber} onChange={(e) => setOpenUser({ ...openUser, applicatorNumber: e.target.value })} />
            </Col>
            <Col>
              <Label>Applicator Expiry:</Label>
              <Input type='date' value={moment.unix(openUser.applicatorExpiry).format('yyyy-MM-DD')} onChange={(e) => setOpenUser({ ...openUser, applicatorExpiry: moment(e.target.value).unix() })} />
            </Col>
          </Row>
          <Row form>
            <label className='center-toggles ml-1'>
              <span>Account disabled</span>
              <Toggle
                defaultChecked={openUser.accessDisabled}
                onChange={(e) => setOpenUser({ ...openUser, accessDisabled: e.target.checked })}
              />
            </label>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="secondary" onClick={() => {
            setOpenUser(null)
            toggle()
          }}>No, cancel</Button>{' '}
          <Button color="primary" onClick={() => updateUser()} disabled={loading}>{loading ? <Loader
            type="Watch"
            color="#00BFFF"
            height={25}
            width={25}
            timeout={30000}
          /> : 'Yes, update'}</Button>
        </ModalFooter>
      </Modal>
    )
  }

  const updatePassword = () => {
    setLoading(true);
    setError(false);
    const { password } = openPasswordReset;
    if (_.size(password) >= 6) {
      axios.post('https://us-central1-mydavco-51473.cloudfunctions.net/changeUserPassword', { ...openPasswordReset, adminId: auth.uid })
        .then(() => {
          setLoading(false);
          toast.success('Password updated', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setOpenPasswordReset(null);
          setShowPasswordModal(false);

        })
        .catch((err) => {
          console.log(err);
          setLoading(false)
          toast.error('User failed to update!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
    } else {
      setLoading(false);
      setError('Please ensure password is greater than 6 characters')
    }
  }

  const resetPasswordModal = () => {
    if (!openPasswordReset) {
      return null;
    }
    return (
      <Modal isOpen={showPasswordModal}>
        <ModalHeader>{openPasswordReset.applicatorName}</ModalHeader>
        <ModalBody>
          <Label>Set password:</Label>
          <Input type='text' onChange={(e) => setOpenPasswordReset({ ...openPasswordReset, password: e.target.value })} />
          <FormText>Must be 6 or greater characters</FormText>
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="secondary" onClick={() => {
            setOpenPasswordReset(null)
            setShowPasswordModal(false)
          }}>No, cancel</Button>{' '}
          <Button color="primary" onClick={() => updatePassword()} disabled={loading}>Yes, update</Button>
        </ModalFooter>
      </Modal>
    )
  }

  const updateUser = () => {
    setLoading(true);
    setError(false);
    const { email, applicatorNumber, applicatorName, applicatorExpiry } = openUser;
    if (!_.isEmpty(email) && applicatorExpiry > 0 && !_.isEmpty(applicatorNumber) && !_.isEmpty(applicatorName)) {
      axios.post('https://us-central1-mydavco-51473.cloudfunctions.net/updateUser', { ...openUser, oldEmail, adminId: auth.uid })
        .then(() => {
          setLoading(false);
          toast.success('User updated!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setOpenUser(null);
          toggle();
          setFilteredData();

        })
        .catch((err) => {
          setLoading(false)
          toast.error('User failed to update!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
    } else {
      setLoading(false);
      setError('Please dont leave blank fields.')
    }
  }

  const exportApplicators = async () => {
    setApplicatorListLoading(true)
    try {
      const response = await axios.get('https://us-central1-mydavco-51473.cloudfunctions.net/exportApplicatorList')
      FileDownload(response.data, 'applicator_list.csv')
      setApplicatorListLoading(false)
    } catch (ex) {
      setApplicatorListLoading(false)
      setError('No results found')
    }
  }

  const subHeaderComponentFilter = () => {
    return (<div className='filter-outter-wrapper'>
      <div className='filter-inner-wrapper'>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText><Icon icon={search} size={20} /></InputGroupText>
          </InputGroupAddon>
          <Input type='text' placeholder='Filter by name or email' onChange={(e) => setSearchValue(e.target.value)} />
        </InputGroup>
      </div>
      <Button className='mt-4' onClick={() => exportApplicators()}>{applicatorListLoading ? <Loader type="Watch" color="#00BFFF" height={25} width={25} timeout={30000} /> : 'Download Applicator List'}</Button>
    </div>)
  }

  const renewApplicatorNumber = async (data) => {
    const { applicatorExpiry } = data
    const now = moment.now();
    const newExpiry = moment(now).add(1, 'year');
    try {
      setRenewLoading(true);

      await axios.post('https://us-central1-mydavco-51473.cloudfunctions.net/updateApplicatorExpiry', { ...data, adminId: auth.uid, newExpiry: newExpiry })
      toast.success('Applicator Renewed', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setRenewLoading(false);
    } catch (err) {
      console.log(err)
      setRenewLoading(false);
      toast.error('Applicator renew failed', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  const ExpanableComponent = ({ data }) => {
    return (
      <div className='expanded-user'>
        <div className='button-wrapper'>
          <Button onClick={() => {
            setOpenUser(data)
            setOldEmail(data.email)
            toggle()
          }}>Edit user</Button>
        </div>
        <div className='button-wrapper'>
          <Button onClick={() => {
            setShowPasswordModal(true)
            setOpenPasswordReset(data)
          }}>Change password</Button>
        </div>
        {moment.unix(data.applicatorExpiry) < moment.now() ?
          <div className='button-wrapper'>
            <Button disabled={renewLoading} color='primary' onClick={() => renewApplicatorNumber(data)}>{renewLoading ? <Loader type="Watch" color="#00BFFF" height={25} width={25} timeout={30000} />
              : 'Renew Applicator Expiry'}</Button>
          </div>
          : null
        }
      </div>
    )
  }

  if (auth.isEmpty) {
    return <LoadingScreen />;
  }
  console.log(users)
  return (
    <Container className='user-container'>
      <DataTable
        style={{ borderRadius: 20 }}
        subHeader
        subHeaderComponent={subHeaderComponentFilter()}
        title="Applicator list"
        columns={columns}
        data={_.isEmpty(filteredData) ? _.map(users) : filteredData}
        theme="solarized"
        conditionalRowStyles={conditionalRowStyles}
        pagination={true}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 15, 30, 50]}
        expandableRows={true}
        expandableRowsComponent={<ExpanableComponent />}
      />
      {userUpdate()}
      {resetPasswordModal()}
    </Container>
  )
}