import React, { useState } from 'react';
import { useStore, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import { Container, Label, Input, Row, Col, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { search } from 'react-icons-kit/feather';
import DataTable from 'react-data-table-component';

import { LoadingScreen } from '../../loading-screen';

import moment from 'moment';
moment.locale(['en-nz', 'en-au']);
import '../../../styles/jobview.css'

export const NormalViewJobs = () => {
  const { auth } = useSelector(state => state.firebaseData);
  const [filteredData, setFilteredData] = useState([]);

 useFirestoreConnect([
    { collection: 'jobs', where: [['ownerId', '==', auth.uid ]], storeAs: 'jobs' },
  ])

  const {data: { jobs } } = useStore().getState().firestoreData;


  const columns = [
    {
      name: 'Applicator name',
      selector: 'applicatorName',
      sortable: true,
    },
    {
      name: 'Client name',
      selector: 'ownerName',
      sortable: true,
      right: true,
    },
    {
      name: 'Site address',
      selector: 'siteAddress',
      sortable: false,
      right: true,
    },    {
      name: 'Warranty certificate',
      selector: 'warrantyCert',
      sortable: false,
      right: true,
      format: row => {return <a href={`${row.warrantyCert}`} target='_blank'>download</a>}
    },
    {
      name: 'Warranty terms',
      selector: 'warrantyTerms',
      sortable: false,
      right: true,
      format: row => {return <a href={`${row.warrantyTerms}` } target='_blank'>download</a>}
    },
  ];

  const conditionalRowStyles= [
    {
      when: row => !_.isEmpty(row.adminNotes),
      style: {
        backgroundColor: '#3e9b39',
        color: 'white',
      },
    },
  ];


  const filter = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
  
    setFilteredData(inputLength === 0 ? _.map(jobs) : _.filter(jobs, (job) => {
      return job.applicatorName.toLowerCase().slice(0, inputLength) === inputValue || job.ownerName.toLowerCase().toLowerCase().slice(0, inputLength) === inputValue ;
    }))
  };




  const subHeaderComponentFilter = () => {
    return(<div className='filter-outter-wrapper'>
            <div className='filter-inner-wrapper'>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><Icon  icon={search} size={20} /></InputGroupText>
              </InputGroupAddon>
              <Input type='text' placeholder='client' onChange={(e)=> filter(e.target.value)} />
            </InputGroup>
            </div>
           </div>)
  }

  const ExpanableComponent = ({data}) => {
    return (
      <div className='expanded-wrapper'>
        <Row className='expanded-row'>
          <Col>
            <Label>City</Label>
            <p>{data.city}</p>
          </Col>
          <Col>
            <Label>Job size (m2)</Label>
            <p>{data.jobSize}</p>
          </Col>
          <Col>
            <Label>Date install started</Label>
            <p>{moment.unix(data.dateInstalled).format('DD/MM/YYYY')}</p>
          </Col>
          <Col>
            <Label>Date install finished</Label>
            <p>{moment.unix(data.dateCompleted).format('DD/MM/YYYY')}</p>
          </Col>
        </Row>
        <Row className='expanded-row'>
          <Label className='extra-margin'>Product details</Label>
          <Col>
            <Label>Water proofing</Label>
            <p>{data.waterProofingUsed} {data.waterProofingBatchNumber ? data.waterProofingBatchNumber : null}</p>
          </Col>
          <Col>
            <Label>Primer</Label>
            <p>{data.primerUsed}</p>
          </Col>
          <Col>
            <Label>Adhesive</Label>
            <p>{data.adhesiveUsed}</p>
          </Col>
          <Col>
            <Label>Bond breaker</Label>
            <p>{data.bondBreaker ? 'Yes' : 'No'}</p>
          </Col>
        </Row>
        <Row className='expanded-row'>
          <Label className='extra-margin'>Purchase details</Label>
          <Col>
            <Label>Place of purchase</Label>
            <p>{data.placeOfPurchase}</p>
          </Col>
          <Col>
            <Label>City</Label>
            <p>{data.placeOfPurchaseCity}</p>
          </Col>
        </Row>
        <Row className='expanded-row'>
          <Label className='extra-margin'>Additional</Label>
          <Col>
            <Label>Installer notes</Label>
            <p>{!_.isEmpty(data.additonalNotes) ? data.additonalNotes : "None"}</p>
          </Col>
        </Row>
      </div>
    )
  }

  if(auth.isEmpty) {
    return <LoadingScreen />;
  }
    return(
        <Container className='job-container'>
              <DataTable
                style={{borderRadius: 20}}
                subHeader
                subHeaderComponent={subHeaderComponentFilter()}
                title="Entered Projects"
                columns={columns}
                data={_.isEmpty(filteredData) ? _.map(jobs) : filteredData}
                theme="solarized"
                conditionalRowStyles={conditionalRowStyles}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 15, 30, 50]}
                expandableRows={true}
                expandableRowsComponent={<ExpanableComponent />}
            />
        </Container>
    )
  }