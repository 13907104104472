import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import { Container, Label, Input, Row, Col, InputGroup, InputGroupAddon, Form, Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { LoadingScreen } from '../../loading-screen';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';
import Loader from 'react-loader-spinner'

import moment from 'moment';

import '../../../styles/createJob.css'



export const CreateJob = () => {
  moment.locale('en-nz');
  const firestore = useFirestore();
  const { auth } = useSelector(state => state.firebaseData);
  const { data } = useSelector(state => state.firestoreData);
  const [error, setError] =useState(null);
  const [loading, setLoading] = useState(false);
  const [newJob, setNewJob] = useState({
    additonalNotes: '',
    applicatorNumber: '',
    adhesiveUsed: '',
    adminNotes: '',
    applicationLocation: '',
    applicatorName: '',
    bondBreaker: '',
    city: '',
    clientEmail: '',
    dateCompleted: null,
    dateInstalled: null,
    dateJobCreated: null,
    easyId: 0,
    exported: false,
    id: null,
    jobSize: '',
    ownerId: null,
    ownerName: '',
    placeOfPurchase: '',
    placeOfPurchaseCity: '',
    primerUsed: '',
    siteAddress: '',
    warrantyCert: null,
    warrantyPeriod: '',
    warrantyTerms: null,
    waterProofBatchNumber: '',
    waterProofingUsed: '',
  })
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showTerms, setShowterms] = useState(false);
  

  useFirestoreConnect([
    { collection: 'settings', doc: 'settings', storeAs: 'settings' },
  ])

  useEffect(()=> {
    if(!auth.isEmpty && data.user && !newJob.ownerId) {
      setNewJob({...newJob, ownerId: auth.uid, applicatorName: data.user.applicatorName, applicatorNumber: data.user.applicatorNumber,
      applicatorEmail: data.user.email})
    }
  }, [data])

  if(auth.isEmpty || !data.user || !data.settings) {
    return <LoadingScreen />;
  }

  const { settings } = data;

  const renderTerms = () => {
    return (
      <Modal isOpen={showTerms}>
        <ModalHeader>Terms & Conditions</ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{__html:settings.terms}}>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={()=>setShowterms(false)}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }

  const validation = () => {
    setLoading(true);
    setError(null);
    if(data.user.accessDisabled) {
      setError(`Sorry you're unable to place new jobs, please contact an administrator`)
      setLoading(false);
      return;
    }
    const { dateInstalled, dateCompleted, siteAddress, city, ownerName,
    waterProofingUsed, adhesiveUsed, primerUsed, applicationLocation, jobSize,
    placeOfPurchase, placeOfPurchaseCity, waterProofBatchNumber, warrantyPeriod, bondBreaker} = newJob;
    let oPrimer = false;
    let oWater = false;
    let oAdhesive = false;
    if(_.isEmpty(dateInstalled) || _.isEmpty(dateCompleted)) {
      if(dateInstalled !== '' || dateCompleted !== '') {
        // continue on allows for empty check then check its not string once filled
      } else {
        setError('Please ensure the install date and completion date has been completed')
        setLoading(false)
        return
      }
    }
    if(dateInstalled > dateCompleted) {
      setError('Please ensure the install date and completion date are correct');
      setLoading(false)
      return
    }
    if(_.isEmpty(siteAddress) || _.isEmpty(city) || _.isEmpty(ownerName)) {
      setError('Please ensure the site address, city and site owner fields are filled in.')
      setLoading(false)
      return
    }
    if(_.isEmpty(waterProofingUsed) || _.isEmpty(adhesiveUsed) || _.isEmpty(primerUsed) || waterProofingUsed === 'Select' || adhesiveUsed === 'Select'
    || primerUsed === 'Select') {
      setError('Please ensure waterproofing type, adhesive type and primer type is selected')
      setLoading(false)
      return
    }
    if(_.isEmpty(bondBreaker) || bondBreaker === 'Select') {
      setError('Please ensure bond breaker is selected')
      setLoading(false)
      return
    } 
    if(primerUsed.toLowerCase() === 'other') {
      if(!newJob.hasOwnProperty('otherPrimer') || _.isEmpty(newJob.otherPrimer)) {
        setError('Please fill in other primer')
        setLoading(false);
        return
      } else {
        oPrimer = true;
      }
    } 
    if(waterProofingUsed.toLowerCase() === 'other') {
      if(!newJob.hasOwnProperty('otherWaterProofing') || _.isEmpty(newJob.otherWaterProofing)) {
        setError('Please fill in other waterproofing')
        setLoading(false);
        return
      } else {
        oWater = true;
      }
    }
    if(adhesiveUsed.toLowerCase() === 'other') {
      if(!newJob.hasOwnProperty('otherAdhesive') || _.isEmpty(newJob.otherAdhesive)) {
        setError('Please fill in other adhesive')
        setLoading(false);
        return
      } else {
        oAdhesive = true;
      }
    }
    if(applicationLocation === 'Select' || _.isEmpty(applicationLocation)) {
      setError('Please select an application location')
      setLoading(false);
      return
    }
    if(_.isEmpty(jobSize)) {
      setError('Please fill in job size')
      setLoading(false);
      return
    }
    if(_.isEmpty(placeOfPurchase)) {
      setError('Please fill in place of purchase')
      setLoading(false);
      return
    }
    if(_.isEmpty(placeOfPurchaseCity)) {
      setError('Please fill in place of purchase city')
      setLoading(false);
      return
    }
    if(_.isEmpty(waterProofBatchNumber)) {
      setError('Please fill in waterproofing batch number')
      setLoading(false);
      return
    }
    if(_.isEmpty(warrantyPeriod) || warrantyPeriod === 'Select') {
      setError('Please select a warranty period')
      setLoading(false);
      return
    }

    setTimeout(async ()=> {
      const doc = await firestore.collection('jobs').doc().get();
      doc.ref.set({
        ...newJob,
        id: doc.id,
        dateJobCreated: moment().unix(),
        primerUsed: oPrimer ? newJob.otherPrimer : newJob.primerUsed,
        waterProofingUsed: oWater ? newJob.otherWaterProofing : newJob.waterProofingUsed,
        adhesiveUsed: oAdhesive ? newJob.otherAdhesive : newJob.adhesiveUsed,
      })
      .then(() => {
        toast.success('Job created!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setLoading(false);
      })
      .catch((err) => {
        toast.error('Job Failed to create!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setLoading(false);
      })
    }, 1000)



  }
  const { dateInstalled } = newJob;
  return (
    <Container className='main-container'>
          <div className='create-wrapper-job'>
          <h3>Submit new Project</h3>
            <Form>
              <Row form>
                <Col>
                <Label>
                  Date of installation
                </Label>
                <Input type='date' onChange={(e) => setNewJob({...newJob, dateInstalled: moment(e.target.value).unix()})}/>
                </Col>
                <Col>
                <Label>
                  Installation completed
                </Label>
                <Input type='date' onChange={(e) => setNewJob({...newJob, dateCompleted: moment(e.target.value).unix()})}/>
                </Col>
              </Row>
              <Row form>
                <Col>
                <Label>
                  Applicator name
                </Label>
                <Input type='text' disabled={true} defaultValue={newJob.applicatorName}/>
                </Col>
                <Col>
                <Label>
                  Applicator number
                </Label>
                <Input type='text' disabled={true} defaultValue={newJob.applicatorNumber}/>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <Label>
                    Site address
                  </Label>
                  <Input type='text' onChange={(e) => setNewJob({...newJob, siteAddress: e.target.value})}/>
                </Col>
                <Col>
                  <Label>
                    City
                  </Label>
                  <Input type='text' onChange={(e) => setNewJob({...newJob, city: e.target.value})}/>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <Label>
                    Site owners name
                  </Label>
                  <Input type='text' onChange={(e) => setNewJob({...newJob, ownerName: e.target.value})}/>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <Label>
                    Primer used
                  </Label>
                  <Input type='select' onChange={(e) => setNewJob({...newJob, primerUsed: e.target.value})}>
                    <option>Select</option>
                    {_.map(settings.primer, (p, index) => {
                      return <option key={index}>{p}</option>
                    })}
                  </Input>
                </Col>
                  {newJob.primerUsed.toLowerCase() === 'other' ? <Col><Label>
                    Specify other
                  </Label><Input type='text'
                  placeholder='Please specify' onChange={(e) => setNewJob({...newJob, otherPrimer: e.target.value})}/></Col> :
                  null}
              </Row>
              <Row form>
                <Col className='mt-2 mb-2'>
                <Label>
                    Bond breaker
                  </Label>
                  <Input type='select' onChange={(e) => setNewJob({...newJob, bondBreaker: e.target.value})}>
                    <option>Select</option>
                    {_.map(settings.bondBreaker, (p, index) => {
                      return <option key={index}>{p}</option>
                    })}
                  </Input>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <Label>
                    Waterproofing used
                  </Label>
                  <Input type='select' onChange={(e) => setNewJob({...newJob, waterProofingUsed: e.target.value})}>
                    <option>Select</option>
                    {_.map(settings.waterProofing, (p, index) => {
                      return <option key={index}>{p}</option>
                    })}
                  </Input>
                </Col>
                  {newJob.waterProofingUsed.toLowerCase() === 'other' ? <Col><Label>
                    Specify other
                  </Label><Input type='text'
                  placeholder='Please specify' onChange={(e) => setNewJob({...newJob, otherWaterProofing: e.target.value})}/></Col> :
                  null}
              </Row>
              <Row form>
                <Col>
                  <Label>
                    Adhesive used
                  </Label>
                  <Input type='select' onChange={(e) => setNewJob({...newJob, adhesiveUsed: e.target.value})}>
                    <option>Select</option>
                    {_.map(settings.adhesive, (p, index) => {
                      return <option key={index}>{p}</option>
                    })}
                  </Input>
                </Col>
                  {newJob.adhesiveUsed.toLowerCase() === 'other' ? <Col><Label>
                    Specify other
                  </Label><Input type='text'
                  placeholder='Please specify' onChange={(e) => setNewJob({...newJob, otherAdhesive: e.target.value})}/></Col> :
                  null}
              </Row>
              <Row form>
                <Col>
                  <Label>
                    Application location
                  </Label>
                  <Input type='select' onChange={(e) => setNewJob({...newJob, applicationLocation: e.target.value})}>
                    <option>Select</option>
                    <option>Internal</option>
                    <option>External</option>
                  </Input>
                </Col>
                <Col>
                  <Label>
                    Job size
                  </Label>
                  <InputGroup>
                    <Input type='number' onChange={(e) => setNewJob({...newJob, jobSize: e.target.value})}/>
                    <InputGroupAddon addonType="append">sqm</InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <Label>
                    Place of purchase
                  </Label>
                  <Input type='text' onChange={(e) => setNewJob({...newJob, placeOfPurchase: e.target.value})}/>
                </Col>
                <Col>
                  <Label>
                    City
                  </Label>
                  <Input type='text' onChange={(e) => setNewJob({...newJob, placeOfPurchaseCity: e.target.value})}/>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <Label>
                    Waterproofing batch number (sticker on bucket lid)
                  </Label>
                  <Input type='text' onChange={(e) => setNewJob({...newJob, waterProofBatchNumber: e.target.value})}/>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <Label>
                    Tradesmen warranty period
                  </Label>
                  <Input type='select' onChange={(e) => setNewJob({...newJob, warrantyPeriod: e.target.value})}>
                    <option>Select</option>
                    {_.map(settings.warranty, (p, index) => {
                      return <option key={index}>{p}</option>
                    })}
                  </Input>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <Label>
                    Additional notes
                  </Label>
                  <Input type='textarea' onChange={(e) => setNewJob({...newJob, additonalNotes: e.target.value})}/>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <Label>
                    Client email
                  </Label>
                  <Input type='email' onChange={(e) => setNewJob({...newJob, clientEmail: e.target.value})}/>
                  <Alert color='warning' className='mt-2 mb-2'>Include your clients email address to automatically send them a copy of the warranty certificates. *optional*</Alert>
                </Col>
              </Row>
              <Row className='terms-row'>
                  <Label>
                  <p>Do you agree to the <a href='#' onClick={()=>setShowterms(true)}>terms and conditions</a>?</p>
                  </Label>
                    <Toggle
                      onChange={(e) => setAgreeTerms(e.target.checked)}
                    />
                    
                    <div className='submit-wrapper mt-4 mb-2'>
                      <Button className='submit-button' disabled={!agreeTerms || loading} onClick={()=> validation()}>{loading ? <Loader
                type="Watch"
                color="#00BFFF"
                height={30}
                width={30}
                timeout={30000}
                />: 'Submit'}</Button>
                      {!agreeTerms ? <Alert color='danger' className='mt-2'>You must agree to the terms before submitting</Alert> : null}
                      {error ? <Alert color='danger' className='mt-2'>{error}</Alert> : null}
                    </div>
              </Row>
            </Form>
          </div>
          {renderTerms()}
    </Container>
  )
  }