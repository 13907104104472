import React from 'react';
import { useStore, useSelector } from 'react-redux';
import _ from 'lodash';


import { LoadingScreen } from '../loading-screen';

import '../../styles/users.css'
import { AdminJobView } from './admin';
import { NormalViewJobs } from './regular';


export const ViewJobs = () => {
  const { auth } = useSelector(state => state.firebaseData);


  const { data: { user } } = useStore().getState().firestoreData;


  if(auth.isEmpty || !user) {
    return <LoadingScreen />;
  }

  if(user.isAdmin) {
    return <AdminJobView />
  } else {
    return <NormalViewJobs />
  }

}