import React, { useEffect, useState } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import { Container, Button, Label, Input, Row, Col, Form, Alert, Modal, ModalHeader, ModalBody, ModalFooter,  } from 'reactstrap';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';
import axios from 'axios';
import { Icon } from 'react-icons-kit';
import { lock, envelope } from 'react-icons-kit/fa';
import Loader from 'react-loader-spinner'

import '../../../styles/login.css'
import { useHistory } from 'react-router-dom';


export const ForceUpdatePW = () => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const history = useHistory();
  const { auth } = useSelector(state => state.firebaseData);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const update = async () => {
    setLoading(true);
    setError(null);
    if(_.isEmpty(password) || password < 6) {
      setError('Please enter a new password 6 or greater characters long.');
      setLoading(false);
      return;
    }

    firebase.auth().currentUser.updatePassword(password)
    .then(async ()=>{
      await firestore.collection('users').doc(auth.uid).update({mustResetPasswordOnLogin: false})
        //history.push('/dashboard')
    })
    .catch(()=>{
      setError("Login has timed out, please try again... redirecting in 3...");
      setTimeout(()=>{
        firebase.logout()
      }, 3000)
    })
    
  }

  return(
    <Container fluid className='login-main-container'>
      <Container className='login-wrapper'>
        <Form className='login-form'>
          <div className='logo-wrapper-dav'>
            <img src={require('../../../images/davco_white_bg.png')} />
          </div>
          <h5 className='text-center mt-4 mb-4'>Please set a <strong>new</strong> password to continue</h5>
          <Input type='password' placeholder='Password'  onChange={(e)=> setPassword(e.target.value)}/>
          {error ? <Alert className='mt-2' color='danger'>{error}</Alert> : null}
          <div className='buttons-wrapper'>
            <Button className='btn-login' disabled={loading} onClick={()=> update()}>{loading ? <Loader
                type="Watch"
                color="#00BFFF"
                height={30}
                width={30}
                timeout={30000}
                />: 'Update'}</Button>
          </div>
        </Form>
      </Container>
    </Container>
  )

}