import React from 'react';
import _ from 'lodash';
import Loader from 'react-loader-spinner'
import { Container } from 'reactstrap';


export const LoadingScreen = () => {
    return(
        <Container fluid={true} className='loading-screen '>
            <div>
                <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={30000} //3 secs
                />
            </div>
        </Container>
    )
}