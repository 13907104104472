import React, { useEffect, useState } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import { Container, Button, Label, Input, Row, Col, Form, Alert, Modal, ModalHeader, ModalBody, ModalFooter,  } from 'reactstrap';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';
import axios from 'axios';
import { Icon } from 'react-icons-kit';
import { lock, envelope } from 'react-icons-kit/fa';
import Loader from 'react-loader-spinner'

import '../../../styles/login.css'
import { useHistory } from 'react-router-dom';


export const Login = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const { auth } = useSelector(state => state.firebaseData);
  const state = useSelector(state => state);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [forgottenEmail, setForgottenEmail] = useState('');
  const [message, setMessage] = useState(null);

  useEffect(() => {
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email, password]);

  useEffect(()=> {
    if(auth && auth.uid) {
      history.push('/dashboard')
    }
  }, [auth])

  const listener = event => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      login()
    }
  };

  const login = () => {
    setLoading(true);
    setError(null);
    if(_.isEmpty(email) || _.isEmpty(password)) {
      setError('Please enter email and password');
      setLoading(false);
      return;
    }

    firebase.login({email, password})
    .then(()=>{
      history.push('/dashboard')
    })
    .catch((err)=> {
      setLoading(false);
      setError('Incorrect username or password.')
      console.log(err);
    })
    
  }

  const resetPassword = () => {
    firebase.resetPassword(forgottenEmail)
    .then(() => {
      setMessage('Please follow the instructions in the sent email')
    })
    .catch((err) => {
      console.log(err);
      setMessage('Error')
    })
  }

  const forgotPassword = () => {
    return (
      <Modal isOpen={forgetPassword}>
        <ModalHeader>Reset Password</ModalHeader>
        <ModalBody className='p-5'>
          <Label>
            Registered Email
          </Label>
          <Input type='email' onChange={(e)=> setForgottenEmail(e.target.value)}/>
          {message ? <Alert color='warning'>{message}</Alert> : null}
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="secondary" onClick={()=>setForgetPassword(false)}>close</Button>{' '}
          <Button color="primary" onClick={()=>resetPassword()} disabled={loading}>Submit</Button>
        </ModalFooter>
      </Modal>
    )
  }

  return(
    <Container fluid className='login-main-container'>
      <div className='construction-banner'>
        <Alert color='primary' className='text-center construction-alert'>The Davco warranty system has been updated. Please check your email for login instructions.
         If you encounter any <strong>bugs / errors</strong> please contact josh@tinydigital.co.nz</Alert>
      </div>
      <Container className='login-wrapper'>
        <Form className='login-form'>
          <div className='logo-wrapper-dav'>
            <img src={require('../../../images/davco_white_bg.png').default} />
          </div>
          <h5 className='text-center mt-2 mb-2'>Warranty Registration</h5>
          <Input type='email' placeholder='Email' onChange={(e)=> setEmail(e.target.value)}/>
          <Input type='password' placeholder='Password'  onChange={(e)=> setPassword(e.target.value)}/>
          {error ? <Alert className='mt-2' color='danger'>{error}</Alert> : null}
          <div className='buttons-wrapper'>
            <Button className='btn-login' disabled={loading} onClick={()=> login()}>{loading ? <Loader
                type="Watch"
                color="#00BFFF"
                height={30}
                width={30}
                timeout={30000}
                />: 'Login'}</Button>
            <Button className='btn-reset' disabled={loading} onClick={()=> setForgetPassword(true)}>Forgot Password</Button>
          </div>
        </Form>
      </Container>
      {forgotPassword()}
    </Container>
  )

}