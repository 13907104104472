import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import 'firebase/database';
import 'firebase/storage';

// process.env.NODE_ENV === 'development'
export function firebaseConfig() {
 if(true) {
  const firebaseConfig = {
    apiKey: "AIzaSyBlpxFSKMTztO9LkORHihipfmkEnlhl8WI",
    authDomain: "mydavco-51473.firebaseapp.com",
    databaseURL: "https://mydavco-51473.firebaseio.com",
    projectId: "mydavco-51473",
    storageBucket: "mydavco-51473.appspot.com",
    messagingSenderId: "81282912262",
    appId: "1:81282912262:web:a6f2c261c32b8eafa73387",
    measurementId: "G-KL007W5ZBC"
  }
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
  firebase.functions();
  return {
    firebase: firebase,
    config: firebaseConfig
  }
 } else {
  const firebaseConfig = {
    apiKey: "AIzaSyCl36sMOrZ0tAURW1HzJsKWyT68qHR_9Is",
    authDomain: "coffee-prod-81f1f.firebaseapp.com",
    databaseURL: "https://coffee-prod-81f1f.firebaseio.com",
    projectId: "coffee-prod-81f1f",
    storageBucket: "coffee-prod-81f1f.appspot.com",
    messagingSenderId: "378581712561",
    appId: "1:378581712561:web:35d8312141fdc36b102c90",
    measurementId: "G-M9S5YL5970"
  };
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
  firebase.functions();
  return {
    firebase: firebase,
    config: firebaseConfig
  }
 }


}