import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import { Container,  Button, Label, Input, Row, Col, Modal, ModalBody, ModalFooter,ModalHeader, Alert } from 'reactstrap';
import JoditEditor from "jodit-react";
import { LoadingScreen } from '../loading-screen';
import { Icon } from 'react-icons-kit';
import { plusCircle } from 'react-icons-kit/feather';
import { minusCircle } from 'react-icons-kit/fa'
import { toast } from 'react-toastify';


import '../../styles/settings.css'
const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  height: 450,
}


export const Settings = () => {
  const firestore = useFirestore();
  const { auth } = useSelector(state => state.firebaseData);
  const { data } = useSelector(state => state.firestoreData);
  const [error, setError] =useState(null);
  const [loading, setLoading] = useState(false);
  const editor = useRef(null)
  const [content, setContent] = useState('');
  const [newPrimer, setNewPrimer] = useState('');
  const [newAdhesive, setNewAdhesive] = useState('');
  const [newWater, setNewWater] = useState('');
  const [newWarranty, setNewWarranty] = useState('');
  const [newBondBreaker, setNewBondBreaker] = useState('');
  const [warningModal, setWarningModal] = useState(false);
  const [removeOption, setRemoveOption] = useState(null);
	

  useFirestoreConnect([
    { collection: 'settings', doc: 'settings', storeAs: 'settings' },
  ])

  useEffect(()=>{
    if (data.hasOwnProperty('user') && data.hasOwnProperty('settings') && settings && _.isEmpty(content)) {
      setContent(data.settings.terms)
    }
  }, [data.settings])

 

  if(auth.isEmpty) {
    return <LoadingScreen />;
  } if (!data.hasOwnProperty('user') && !data.hasOwnProperty('settings') && !settings) {
    return <LoadingScreen />;
  }

  const removeOptionModal = () => {
    if(!removeOption) {
      return null
    }
    return (
      <Modal isOpen={warningModal}>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to remove <strong>{removeOption.option}</strong> from <strong>{removeOption.category}</strong></p>
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="secondary" onClick={()=>setWarningModal(false)}>Close</Button>{' '}
          <Button color="primary" onClick={()=>deleteOption()} disabled={loading}>Yes, remove</Button>
        </ModalFooter>
      </Modal>
    )
  }

  const deleteOption = () => {
    setLoading(true);
    const update = _.filter(settings[removeOption.category], (cat) => {
      return cat !== removeOption.option
    })
    firestore.collection('settings').doc('settings').update({
      [removeOption.category]: [...update]
    })
    .then(()=> {
      setLoading(false);
      setWarningModal(false);
      removeOption(null);
    })
    .catch((err)=> {
      console.log(err)
    })
  }

  const addOption = (category) =>{
    setLoading(true)
    const add = _.map(settings[category]);
    if(category === 'primer') {
      add.push(newPrimer)
      setNewPrimer('')
    }
    if(category === 'adhesive') {
      add.push(newAdhesive)
      setNewAdhesive('')
    }
    if(category === 'waterProofing') {
      add.push(newWater)
      setNewWater('')
    }
    if(category === 'warranty') {
      add.push(newWarranty)
      setNewWarranty('')
    }
    if(category === 'bondBreaker') {
      add.push(newBondBreaker)
      setNewWarranty('')
    }
    firestore.collection('settings').doc('settings').update({
      [category]: [...add]
    })
    .then(()=> {
      setLoading(false);
    })
    .catch((err)=> {
      console.log(err)
    })
  }

  const renderOption = (data, category) => {
    return _.map(data, (d, index) => {
      return(
        <div key={index} className='option'>
          <p>{d}</p>
          <Icon icon={minusCircle} className='delete-icon' onClick={()=> {
            setWarningModal(true);
            setRemoveOption({option: d, category})
          }}/>
        </div>
      )
    })
  }

  const updateTerms = () => {
    setLoading(true);
    firestore.collection('settings').doc('settings').update({
      terms: content
    })
    .then(()=>{
      setLoading(false);
      toast.success('Updated', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    })
    .catch((err) => {
      setLoading(false)
      console.log(err);
    })
  }

  const { settings } = data;

  const renderEditor = () => {
    if(!settings.terms) {
      return null
    } else {
      return (
      <div>
       <JoditEditor
        style={{maxHeight: 350}}
        ref={editor}
        value={content}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={newContent => setContent(newContent)}   
        />
         <div className='button-wrapper'>
          <Button className='btn-create' disabled={loading} onClick={()=> updateTerms()}>Update Terms</Button>
        </div>
      </div>
      )
    }
  }

  const renderSettings = () => {
   if(settings) {
     return(
      <Container className='main-container'>
      <div className='create-wrapper-settings '>
          <h3>Settings</h3>
          <Row>
            <Col>
            <Row form>
              <Col>
                  <Label>Primer</Label>
                  <div className='input-wrapper'>
                    <Input type='text' onChange={(e)=> setNewPrimer(e.target.value)}/>
                    {!_.isEmpty(newPrimer) ? <Icon icon={plusCircle}size={20}  className='add-icon' onClick={()=>addOption('primer')}/> : null}
                  </div>
                  <div className='option-wrapper'>
                    {renderOption(settings.primer, 'primer')}
                  </div>
              </Col>
              <Col>
                  <Label>Adhesive</Label>
                  <div className='input-wrapper'>
                    <Input type='text' onChange={(e)=> setNewAdhesive(e.target.value)}/>
                    {!_.isEmpty(newAdhesive) ? <Icon icon={plusCircle}size={20}  className='add-icon' onClick={()=>addOption('adhesive')}/> : null}
                  </div>
                  <div className='option-wrapper'>
                    {renderOption(settings.adhesive, 'adhesive')}
                  </div>
                  
              </Col>
          </Row>
          <Row form>
              <Col>
                  <Label>Waterproofing</Label>
                  <div className='input-wrapper'>
                    <Input type='text' onChange={(e)=> setNewWater(e.target.value)}/>
                    {!_.isEmpty(newWater) ? <Icon icon={plusCircle}size={20}  className='add-icon' onClick={()=>addOption('waterProofing')}/> : null}
                  </div>
                  <div className='option-wrapper'>
                    {renderOption(settings.waterProofing, 'waterProofing')}
                  </div>          
              </Col>
              <Col>
                  <Label>Warranty</Label>
                  <div className='input-wrapper'>
                    <Input type='text' onChange={(e)=> setNewWarranty(e.target.value)}/>
                    {!_.isEmpty(newWarranty) ? <Icon icon={plusCircle}size={20}  className='add-icon' onClick={()=>addOption('warranty')}/> : null}
                  </div>
                  <div className='option-wrapper'>
                    {renderOption(settings.warranty, 'warranty')}  
                  </div>
                
              </Col>   
          </Row>
          <Row form>
              <Col>
                  <Label>Bond breaker</Label>
                  <div className='input-wrapper'>
                    <Input type='text' onChange={(e)=> setNewBondBreaker(e.target.value)}/>
                    {!_.isEmpty(newBondBreaker) ? <Icon icon={plusCircle}size={20}  className='add-icon' onClick={()=>addOption('bondBreaker')}/> : null}
                  </div>
                  <div className='option-wrapper'>
                    {renderOption(settings.bondBreaker, 'bondBreaker')}
                  </div>          
              </Col> 
          </Row>
            </Col>
            <Col>
              <Label>Terms text</Label>
                {renderEditor()}
            </Col>
         </Row>
          {error? <Alert color='danger' className='mt-5'>{error}</Alert> : null}
      </div>
      {removeOptionModal()}
  </Container>
     )
   } else {
     return null;
   }
  }

    return(
      renderSettings()
    )
  }