import 'date-input-polyfill-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import AuthWrapper from './components/auth/AuthWrapper';
import 'bootstrap/dist/css/bootstrap.css';
import { Login } from './components/auth/login/index';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import configureStore from '../src/reducers/index';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import "../src/styles/main.css"
import "react-toggle/style.css"
import 'react-toastify/dist/ReactToastify.css';
import { Dashboard } from './components/dashboard';
import { CreateUser } from './components/users/create';
import { CreateJob } from './components/jobs/create';
import { Users } from './components/users';
import { Settings } from './components/settings';
import { ViewJobs } from './components/jobs';
import { Exports } from './components/exports';



console.log(process.env.NODE_ENV)
const initDetails = configureStore();
const store = initDetails.store;
const rrfProps = initDetails.rrfProps;
ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps} >
      <Router>
        <Switch>  
          <Route exact path='/' component={Login} />
          <Route path='/exports' component={AuthWrapper(Exports, true)} />
          <Route path='/dashboard' component={AuthWrapper(Dashboard, true)} />
          <Route path='/users/create' component={AuthWrapper(CreateUser, true)} />
          <Route path='/jobs/create' component={AuthWrapper(CreateJob, true)} />
          <Route path='/jobs' component={AuthWrapper(ViewJobs, true)} />
         
          <Route path='/users' component={AuthWrapper(Users, true)} />
          <Route path='/settings' component={AuthWrapper(Settings, true)} />
        </Switch>
      </Router>
    </ReactReduxFirebaseProvider>
  </Provider>,
document.getElementById('root')
);
