import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import _ from 'lodash';
import { Container, Button, Label, Input, Row, Col, Alert, FormText } from 'reactstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from 'react-loader-spinner'
import moment from 'moment';

import { LoadingScreen } from '../../loading-screen';


import '../../../styles/userCreate.css'

moment.locale('en-nz')
export const CreateUser = () => {
  const firestore = useFirestore();
  const [appNumber, setAppNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { auth } = useSelector(state => state.firebaseData)
  const [newMember, setNewMember] = useState({
    userType: '-',
    email: '', applicatorName: '', applicatorNumber: '', applicatorExpiry: null, accessDisabled: false
  })
  const [generatedPass, setGeneratedPass] = useState('');

  if (auth.isEmpty) {
    return <LoadingScreen />;
  }

  useEffect(() => {
    const applicatorNumberGen = async () => {
      //this is actually set server side.
      // display purpose only
      const now = moment.now();
      const year = moment(now).get('year').toString();
      const newPrefix = year.substring(2, year.length)
      const numberData = await firestore.collection('settings').doc('status').get();
      const applicatorNum = newPrefix + "/" + numberData.data().applicatorID
      setAppNumber(applicatorNum)
    };
    applicatorNumberGen();
  },[loading])


  const createMember = async () => {
    const { email, applicatorName, applicatorNumber, applicatorExpiry, userType, password } = newMember;
    setError(null);
    setLoading(true);
    if (_.isEmpty(email) || _.isEmpty(applicatorName) || _.isEmpty(userType) || userType === '-' || !applicatorExpiry) {
      setError('Please complete each field.');
      setLoading(false);
    }
    else {
      const charcs = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
        '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      let count = 0;
      let newPass = '';
      while (count < 7) {
        newPass = newPass + charcs[Math.floor(Math.random() * _.size(charcs))]
        count++;
      }
      axios.post('https://us-central1-mydavco-51473.cloudfunctions.net/createUser', { ...newMember, password: newPass })
        .then(() => {
          toast.success('User created!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setGeneratedPass(newPass);
          setLoading(false);
          setNewMember({
            userType: '-',
            email: '', applicatorName: '', applicatorNumber: '', applicatorExpiry: null, accessDisabled: false
          })
        })
        .catch(err => {
          console.log(err.response);
          toast.error(`Failed to create user! ${err.response.data.message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
        })
    }
  }


  return (
    <Container className='main-container'>
      <div className='create-wrapper'>
        <h3>New User</h3>
        <Row form>
          <Col>
            <Label>User Type</Label>
            <Input type='select' value={newMember.userType} onChange={(e) => setNewMember({ ...newMember, userType: e.target.value })}>
              <option value="-">Select</option>
              <option value="admin">Admin</option>
              <option value="regular">Regular</option>
            </Input>
          </Col>
          <Col>
            <Label>Email</Label>
            <Input type='email' value={newMember.email} onChange={(e) => setNewMember({ ...newMember, email: e.target.value })} />
          </Col>
        </Row>
        <Row form>
          <Col>
            <Label>Applicator Name</Label>
            <Input type='text' value={newMember.applicatorName} onChange={(e) => setNewMember({ ...newMember, applicatorName: e.target.value })} />
          </Col>
          <Col>
            <Label>Applicator #</Label>
            <Input type='text' disabled={true} defaultValue={appNumber} />
          </Col>
        </Row>
        <Row form>
          <Col>
            <Label>Applicator Expiry</Label>
            <Input type='date' onChange={(e) => setNewMember({ ...newMember, applicatorExpiry: moment(e.target.value).unix() })} />
          </Col>
        </Row>
        {error ? <Alert color='danger' className='mt-5'>{error}</Alert> : null}
        {generatedPass ? <Alert color='success' className='mt-5'>Generated password: {generatedPass}</Alert> : null}
        <FormText className='text-center mt-2 mb-2'>The user will be emailed their credentials</FormText>
        <div className='button-wrapper'>
          <Button className='btn-create' disabled={loading} onClick={() => createMember()}>{loading ? <Loader
            type="Watch"
            color="#00BFFF"
            height={25}
            width={25}
            timeout={30000}
          /> : 'Create'}</Button>
        </div>
      </div>
    </Container>
  )
}