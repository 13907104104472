import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import { NavMenu } from '../nav/index';
import { ToastContainer } from 'react-toastify';
import { ForceUpdatePW } from '../auth/changePasswordOnLogin/index';
import { Row, Col } from 'reactstrap';

export default function (ComposedComponent, applyNav) {
  class AuthWrapper extends Component {

    componentDidUpdate() {
        
      if (!this.props.firebaseData) {
        return false;
      }
      const { auth: { isEmpty, isLoaded } } = this.props.firebaseData;
      if (isLoaded && !isEmpty) {
        const { auth: { authError, stsTokenManager } } = this.props.firebaseData;
        if (authError || (stsTokenManager && !stsTokenManager.accessToken)) {
          this.props.firebase.logout()
        }
      } else if (isLoaded && isEmpty) {
        this.props.history.push('/')
      }
    }


    render() {
      const { auth: { isLoaded } } = this.props.firebaseData;
      const { data: { user } } = this.props.firestoreData;
      if (!isLoaded && !user) {
        return false;
      }
      
      if(user && user.hasOwnProperty('mustResetPasswordOnLogin') && user.mustResetPasswordOnLogin) {
        return <ForceUpdatePW />
      }
      if(applyNav) {
          return(
            <Row>
              <Col xl={1} lg={1} md={1} sm={1}>
                <NavMenu />
                <ToastContainer />
              </Col>
                <Col>
                <ComposedComponent {...this.props} id={'page-wrap'}/>
                </Col>

            </Row>
          )
      }
      return <ComposedComponent {...this.props} /> ;
    }
    
  }

  function mapToFirestore({ firebaseData })  {
    if(firebaseData.auth.isLoaded){
      return [
        {
          collection: 'users',
          doc: firebaseData.auth.uid,
          storeAs: 'user'
        }
      ]
    }
    return []
  }
 
  function mapStateToProps({firebaseData, firestoreData}) {

   return {
    firebaseData,
    firestoreData
   }
  }


  return compose(firebaseConnect(), connect(mapStateToProps), firestoreConnect(mapToFirestore))(AuthWrapper);;
}

