import React, { useState } from 'react';
import { useStore, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import { Container, Row, Col, Label, Input, Button, FormText, Alert } from 'reactstrap';
import DataTable from 'react-data-table-component';

import { LoadingScreen } from '../loading-screen';

import moment from 'moment';

import '../../styles/jobview.css'
import axios from 'axios';
const FileDownload = require('js-file-download');

moment.locale('en-nz')
export const Exports = () => {
  const { auth } = useSelector(state => state.firebaseData);
  const [exportData, setExportData] = useState({fromDate: null, toDate: null})
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

 useFirestoreConnect([
    { collection: 'exports', storeAs: 'exports' },
  ])

  const {data, data: { exports, user } } = useStore().getState().firestoreData;


  const columns = [
    {
      name: 'Export date',
      selector: 'exportTime',
      sortable: true,
      format: row => {return moment(row.exportTime).format('l')}
    },
    {
      name: 'Download',
      selector: 'exportCsvUrl',
      sortable: true,
      right: true,
      format: row => {return <a href={`${row.exportCsvUrl}`} target='_blank'>download</a>}
    },
  ];
  const exportRequest = async () => {
    setLoading(true);
    setError(null);
    if(exportData.fromDate !== null || exportData.toDate !== null) {
      if(exportData.fromDate > exportData.toDate || exportData.toDate < exportData.fromDate) {
        setError('Ensure the dates are around the right way')
        setLoading(false);
      } else {
        try{
          const response = await axios.post('https://us-central1-mydavco-51473.cloudfunctions.net/customExport',{...exportData})
          FileDownload(response.data, 'export.csv')
         } catch(ex) {
          setLoading(false);
          setError('No results found')
         }
      }

     
    } else {
      setLoading(false);
      setError('Please include the from and to dates')
    }
  }

  const customExport = () => {
    return(<div className='filter-outter-wrapper'>
              <div className='filter-inner-wrapper'>
                <h4>Custom Export</h4>
                <FormText>This will not create an export record below or affect the regular monthly export</FormText>
                <Row>
                  <Col>
                    <Label>From</Label>
                    <Input type='date' onChange={(e)=> setExportData({...exportData, fromDate: moment(e.target.value).unix()})}/>
                  </Col>
                  <Col>
                    <Label>To</Label>
                    <Input type='date' onChange={(e)=> setExportData({...exportData, toDate: moment(e.target.value).unix()})}/>
                  </Col>
                </Row>
                <div className='export-btn-wrapper'>
                  <Button onClick={()=> exportRequest()} disabled={loading}>Export</Button>
                </div>
                  {error ? <Alert className='text-center' color='danger'>{error}</Alert> : null}
              </div>
           </div>)
  }

  

  if(auth.isEmpty) {
    return <LoadingScreen />;
  }
  
  if(!data.hasOwnProperty('user') || !data.hasOwnProperty('exports')) {
    return <LoadingScreen />;
  }
  if(!user.isAdmin) {
    return <LoadingScreen />;
  }

    return(
        <Container className='job-container'>
              <DataTable
                style={{borderRadius: 20}}
                title="Monthly Exports"
                subHeader
                subHeaderComponent={customExport()}
                columns={columns}
                data={_.map(exports)}
                theme="solarized"
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 15, 30, 50]}
            />
        </Container>
    )
  }