
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import Icon from 'react-icons-kit';
import { home, cogs, users, calendar, user, userPlus, signOut, userCircle, download } from 'react-icons-kit/fa';
import  _ from 'lodash';


import '../../styles/nav.css';


export const NavMenu = () => {
  const { data } = useSelector(state => state.firestoreData);
  const history = useHistory();
  const firebase = useFirebase();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showJobsMenu, setShowJobsMenu] = useState(false);
  const [activePath, setActivePath] = useState(history.location.pathname)


  const navigate = (path) => {
    history.push(path);
  }

  if(_.isEmpty(data)) {
    return null;
  }

  const { user: { isAdmin } } = data;

  const topBar = () => {
    return(
      <div className='top-bar-wrapper'>
        <div className='top-details-wrapper'>
          <Icon icon={userCircle} size={24} />
          <p>{data.user.applicatorName}</p>
        </div>
      </div>
    )
  }

  const renderUserMenu = () => {
    return (
        <div className='pop-menu'>
           <div className='pop-menu-item' onClick={()=> navigate('/users')}>
            <Icon icon={user} size={18} />
              <p>View / Edit</p>
          </div>
          <div className='pop-menu-item' onClick={()=> navigate('/users/create')}>
            <Icon icon={userPlus} size={18} />
              <p>Create</p>
          </div>
        </div>
    )
  }

  const renderJobMenu = () => {
    return (
        <div className='pop-menu'>
           <div className='pop-menu-item' onClick={()=> navigate('/jobs')}>
            <Icon icon={calendar} size={18} />
              <p>View</p>
          </div>
          <div className='pop-menu-item' onClick={()=> navigate('/jobs/create')}>
            <Icon icon={calendar} size={18} />
              <p>Create</p>
          </div>
        </div>
    )
  }

  const renderAdminMenu = () => {
    return (
      <div>
        {topBar()}
      <div className='main-nav-container'>
        <div className={`navitem-wrapper ${activePath === '/dashboard' ? 'nav-active' : null}`} onClick={()=> navigate('/dashboard')}>
          <Icon icon={home} size={40} />
          <p>Home</p>
        </div>
        <div className={`navitem-wrapper ${activePath === '/exports' ? 'nav-active' : null}`} onClick={()=> navigate('/exports')}>
          <Icon icon={download} size={40} />
          <p>Exports</p>
        </div>
        <div className={`navitem-wrapper ${_.includes(activePath, 'jobs') ? 'nav-active' : null}`}  
          onMouseEnter={()=> setShowJobsMenu(true)} onMouseLeave={()=> setShowJobsMenu(false)}>
          <Icon icon={calendar} size={40} />
          <p>Projects</p>
          {showJobsMenu ? renderJobMenu() : null}
        </div>
        <div className={`navitem-wrapper ${activePath === '/settings' ? 'nav-active' : null}`} onClick={()=> navigate('/settings')}>
          <Icon icon={cogs} size={40} />
          <p>Settings</p>
        </div>
        <div className={`navitem-wrapper ${_.includes(activePath, 'users') ? 'nav-active' : null}`}
        onMouseEnter={()=> setShowUserMenu(true)} onMouseLeave={()=> setShowUserMenu(false)}>
          <Icon icon={users} size={40} />
          <p>Users</p>
          {showUserMenu ? renderUserMenu() : null}
        </div>
        <div className='navitem-wrapper logout' onClick={()=> firebase.logout()}>
          <Icon icon={signOut} size={40}/>
          <p>Logout</p>
        </div>
      </div>
    </div>
    )
  }

  const renderMenu = () => {
    return(
      <div>
        {topBar()}
        <div className='main-nav-container'>
          <div className={`navitem-wrapper ${activePath === '/dashboard' ? 'nav-active' : null}`} onClick={()=> navigate('/dashboard')}>
            <Icon icon={home} size={40} />
            <p>Home</p>
          </div>
          <div className={`navitem-wrapper ${_.includes(activePath, 'jobs') ? 'nav-active' : null}`}  
          onMouseEnter={()=> setShowJobsMenu(true)} onMouseLeave={()=> setShowJobsMenu(false)}>
            <Icon icon={calendar} size={40} />
            <p>Projects</p>
            {showJobsMenu ? renderJobMenu() : null}
          </div>
          <div className='navitem-wrapper logout' onClick={()=> firebase.logout()}>
            <Icon icon={signOut} size={40}/>
            <p>Logout</p>
          </div>
        </div>
      </div>
    )
  }


  // which menues to show
  if(isAdmin) {
    return renderAdminMenu()
  } else {
    return renderMenu();
  }
  

}