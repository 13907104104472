import { firebaseReducer } from 'react-redux-firebase';
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore';
import { createStore, applyMiddleware, compose  } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import { firebaseConfig }  from '../configs/firebase/index';

export default function configureStore(initialState={}) {
  const { firebase } = firebaseConfig();
  const storeCreate = compose(
    applyMiddleware(thunk),
  )(createStore)
  const store = storeCreate(combine(), initialState);
  const config = {
    userProfiles: 'users',
    useFirestoreForProfile: true,
  }
  const rrfProps = {
    firebase,
    config,
    dispatch: store.dispatch,
    createFirestoreInstance
  }
  return {
    store,
    rrfProps,
  }
 }

function combine() {
  return combineReducers({
    firestoreData: firestoreReducer,
    firebaseData: firebaseReducer,
  });
}