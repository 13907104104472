import React, { useState } from 'react';
import { useStore, useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import { Container, Button, Label, Input, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { edit, search } from 'react-icons-kit/feather';
import DataTable from 'react-data-table-component';

import { LoadingScreen } from '../../loading-screen';


import moment from 'moment';

import '../../../styles/jobview.css'

moment.locale('en-nz')
export const AdminJobView = () => {
  const firestore = useFirestore();
  const { auth } = useSelector(state => state.firebaseData);
  const [filteredData, setFilteredData] = useState([]);
  const [editAdminNoteModal, setEditAdminNoteModal] = useState(false);
  const [jobEdit, setJobEdit] = useState(false);
  const [loading, setLoading] = useState(false);

 useFirestoreConnect([
    { collection: 'jobs', storeAs: 'jobs' },
  ])

  const {data: { jobs, user } } = useStore().getState().firestoreData;


  const columns = [
    {
      name: 'Date Project Logged',
      selector: 'dateJobCreated',
      sortable: true,
      format: row => {return moment.unix(row.dateJobCreated).format('DD/MM/YYYY')}
    },
    {
      name: 'Applicator name',
      selector: 'applicatorName',
      sortable: true,
    },
    {
      name: 'Client name',
      selector: 'ownerName',
      sortable: true,
      right: true,
    },
    {
      name: 'Site address',
      selector: 'siteAddress',
      sortable: false,
      right: true,
    },    {
      name: 'Warranty certificate',
      selector: 'warrantyCert',
      sortable: false,
      right: true,
      format: row => {return <a href={`${row.warrantyCert}`} target='_blank'>download</a>}
    },
    {
      name: 'Warranty terms',
      selector: 'warrantyTerms',
      sortable: false,
      right: true,
      format: row => {return <a href={`${row.warrantyTerms}` } target='_blank'>download</a>}
    },
  ];

  const conditionalRowStyles= [
    {
      when: row => !_.isEmpty(row.adminNotes),
      style: {
        backgroundColor: '#3e9b39',
        color: 'white',
      },
    },
  ];


  const filter = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
  
    setFilteredData(inputLength === 0 ? _.map(jobs) : _.filter(jobs, (job) => {
      return job.applicatorName.toLowerCase().slice(0, inputLength) === inputValue || job.ownerName.toLowerCase().toLowerCase().slice(0, inputLength) === inputValue ;
    }))
  };
  
  const updateAdminNote = () => {
    setLoading(true);
    firestore.collection('jobs').doc(jobEdit.id).update({
      adminNotes: jobEdit.adminNotes,
    })
    .then(()=> {
      setLoading(false)
    })
  }


  const adminNoteModal = () => {
    if(!setJobEdit) {
      return null
    }
    return (
      <Modal isOpen={editAdminNoteModal}>
        <ModalHeader>Admin Notes</ModalHeader>
        <ModalBody>
          <Label>Update note</Label>
          <Input type='textarea' value={jobEdit.adminNotes} onChange={(e)=> setJobEdit({...jobEdit, adminNotes: e.target.value})}/>
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="secondary" onClick={()=>{setEditAdminNoteModal(false)}}>Close</Button>{' '}
          <Button color="primary" onClick={()=>updateAdminNote()} disabled={loading}>Yes, update</Button>
        </ModalFooter>
      </Modal>
    )
  }



  const subHeaderComponentFilter = () => {
    return(<div className='filter-outter-wrapper'>
            <div className='filter-inner-wrapper'>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><Icon  icon={search} size={20} /></InputGroupText>
              </InputGroupAddon>
              <Input type='text' placeholder='Applicator or client' onChange={(e)=> filter(e.target.value)} />
            </InputGroup>
            </div>
           </div>)
  }

  const ExpanableComponent = ({data}) => {
    return (
      <div className='expanded-wrapper'>
        <Row className='expanded-row'>
          <Col>
            <Label>City</Label>
            <p>{data.city}</p>
          </Col>
          <Col>
            <Label>Job size (m2)</Label>
            <p>{data.jobSize}</p>
          </Col>
          <Col>
            <Label>Date install started</Label>
            <p>{moment.unix(data.dateInstalled).format('DD/MM/YYYY')}</p>
          </Col>
          <Col>
            <Label>Date install finished</Label>
            <p>{moment.unix(data.dateCompleted).format('DD/MM/YYYY')}</p>
          </Col>
        </Row>
        <Row className='expanded-row'>
          <Label className='extra-margin'>Product details</Label>
          <Col>
            <Label>Water proofing</Label>
            <p>{data.waterProofingUsed} {data.waterProofingBatchNumber ? data.waterProofingBatchNumber : null}</p>
          </Col>
          <Col>
            <Label>Primer</Label>
            <p>{data.primerUsed}</p>
          </Col>
          <Col>
            <Label>Adhesive</Label>
            <p>{data.adhesiveUsed}</p>
          </Col>
          <Col>
            <Label>Bond breaker</Label>
            <p>{data.bondBreaker ? 'Yes' : 'No'}</p>
          </Col>
        </Row>
        <Row className='expanded-row'>
          <Label className='extra-margin'>Purchase details</Label>
          <Col>
            <Label>Place of purchase</Label>
            <p>{data.placeOfPurchase}</p>
          </Col>
          <Col>
            <Label>City</Label>
            <p>{data.placeOfPurchaseCity}</p>
          </Col>
        </Row>
        <Row className='expanded-row'>
          <Label className='extra-margin'>Additional</Label>
          <Col>
            <Label>Installer notes</Label>
            <p>{!_.isEmpty(data.additonalNotes) ? data.additonalNotes : "None"}</p>
          </Col>
          <Col className='admin-notes-col'>
            <Label>Administration notes <Icon icon={edit} size={16} className='edit-icon' onClick={()=> {
              setEditAdminNoteModal(true)
              setJobEdit(data);
            }}/></Label>
            <p>{!_.isEmpty(data.adminNotes) ? data.adminNotes : "None"}</p>
          </Col>
        </Row>
      </div>
    )
  }

  if(auth.isEmpty || !user.isAdmin) {
    return <LoadingScreen />;
  }
    return(
        <Container className='job-container'>
              <DataTable
                style={{borderRadius: 20}}
                subHeader
                subHeaderComponent={subHeaderComponentFilter()}
                title="Entered Projects"
                columns={columns}
                data={_.isEmpty(filteredData) ? _.map(jobs) : filteredData}
                theme="solarized"
                conditionalRowStyles={conditionalRowStyles}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 15, 30, 50]}
                expandableRows={true}
                expandableRowsComponent={<ExpanableComponent />}
            />
            {adminNoteModal()}
        </Container>
    )
  }