import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import _ from 'lodash';
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, FormText, Alert, Row, Col } from 'reactstrap';
import '../../styles/dash.css';
import { LoadingScreen } from '../loading-screen';
import { toast } from 'react-toastify';


import moment from 'moment';
moment.locale('en-nz')

export const Dashboard = () => {
  const { auth } = useSelector(state => state.firebaseData);
  const { data } = useSelector(state => state.firestoreData);
  const firebase = useFirebase();
  const [showModal, setShowModal] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  if(auth.isEmpty || _.isEmpty(data)) {
    return <LoadingScreen />;
  }

  if(data.user.mustResetPasswordOnLogin) {
    return <LoadingScreen />;
  }

  const updatePassword = async () => {
    setLoading(true);
    setError(false);
    if(_.size(newPassword) >= 6) {
      const user = firebase.auth().currentUser;
      user.updatePassword(newPassword)
      .then(()=> {
        toast.success('Password updated', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setLoading(false);
          setShowModal(false);
      })
      .catch((er) => {
        toast.error('Password update failed', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setLoading(false);
        console.log(er);
      })
    } else { 
      setLoading(false);
      setError('Please ensure password is greater than 6 characters')
    }
  }

  const resetPasswordModal = () => {
    return (
      <Modal isOpen={showModal}>
      <ModalHeader>Change password</ModalHeader>
      <ModalBody>
        <Label>Set password:</Label>
        <Input type='text' onChange={(e) => setNewPassword(e.target.value)}/>
        <FormText>Must be 6 or greater characters</FormText>
        {error ? <Alert color='danger' className='mt-2 mb-2'>{error}</Alert> : null}
      </ModalBody>
      <ModalFooter>
        <Button disabled={loading} color="secondary" onClick={()=>{setNewPassword('')
        setShowModal(false)}}>No, cancel</Button>{' '}
        <Button color="primary" onClick={()=>updatePassword()} disabled={loading}>Yes, update</Button>
      </ModalFooter>
    </Modal>
    )
  }

  const renderDownloadCert = () => {
    if(data.user.hasOwnProperty('certUrl')) {
      return(
      <div className='mt-2'>
        <a href={`${data.user.certUrl}` } target='_blank'><Button>Download Cert</Button></a>
      </div>
      )
    } else {
      return null;
    }
  }

    return(
      <div>
        <Container className='main-container-dash'>
          <div className='img-wrapper'>
          <img src={require('../../images/davco_white_bg.png').default} className='svg-image'/>
          <h3 className='w-100 text-center header'>Please select an option from the left menu</h3>
          </div>
   
          <Row>
            <Col>
            <p><strong>Applicator number:</strong> {data.user.applicatorNumber}</p>
            <p><strong>Expiry:</strong> {`${moment.unix(data.user.applicatorExpiry).format('DD/MM/YYYY')}`}</p>
            <p><strong>Name: </strong>{data.user.applicatorName}</p>
            <p><strong>Account type: </strong>{data.user.isAdmin ? 'Admin' : 'Applicator'}</p>
            <div>
              <Button onClick={()=> setShowModal(true)}>Change password</Button>
            </div>
            {renderDownloadCert()}
            </Col>
            <Col className='right-col-dash' lg={4} md={4} xlg={4}>
            <h4>Question?</h4>
            <p>Ask us for site specification or technical information today.</p>
            <p><a href="mailto:davco@tilemax.co.nz">davco@tilemax.co.nz</a></p>
            </Col>
         
          </Row>
          {resetPasswordModal()}
        </Container>
      </div>
    )
  }